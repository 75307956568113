// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-capoeira-js": () => import("./../../../src/pages/capoeira.js" /* webpackChunkName: "component---src-pages-capoeira-js" */),
  "component---src-pages-csoport-js": () => import("./../../../src/pages/csoport.js" /* webpackChunkName: "component---src-pages-csoport-js" */),
  "component---src-pages-edzesek-js": () => import("./../../../src/pages/edzesek.js" /* webpackChunkName: "component---src-pages-edzesek-js" */),
  "component---src-pages-hirek-js": () => import("./../../../src/pages/hirek.js" /* webpackChunkName: "component---src-pages-hirek-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tabor-js": () => import("./../../../src/pages/tabor.js" /* webpackChunkName: "component---src-pages-tabor-js" */)
}

